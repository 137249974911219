/* Google Font CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
.contac-hero-img{
  width: 100%;
}
.contac-hero-img>img{

  width: 100%;
}
.Submit-btn{
  background: #3e2093;
border: none;
border-radius: 6px;
color: #fff;
cursor: pointer;
font-size: 18px;
outline: none;
padding: 8px 16px;
transition: all .3s ease;
}
.success-message{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #595959;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  transition: ease-in .09s;
}
.success-message>p{
  line-height: 25px;
  padding: 20px;
}
.Locations-container{
    width: 85%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}
.cities-location{
    width: 200px;
    height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

}
.cities-location>h3{
 background-color: white;
 width: 100%;
 text-align: center;
 margin-bottom: 30px;

}
.Hyderabd-c{
    background-image: url(../../Copmponents/Assets/hyderabad.jpg);
}
.Banglore-c{
    background-image: url(../../Copmponents/Assets//Banglore.jpg);
}
.Chennai-c{
    background-image: url(../../Copmponents/Assets/Chennai.jpg);
}
.Vizag-c{
    background-image: url(../../Copmponents/Assets/Vizag.jpg);
}
.Vijayawada-c{
    background-image: url(../../Copmponents/Assets/Vijayawada.jpg);
}
.contact-page{
 padding: 20px 0px;
  width: 100%;
  background: #c8e8e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container{
  width: 85%;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.container .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .content .left-side{
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details{
  margin: 14px;
  text-align: center;
}
.content .left-side .details i{
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.content .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two{
  font-size: 14px;
  color: #afafb6;
  word-break: break-all;
}
.container .content .right-side{
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text{
  font-size: 23px;
  font-weight: 600;
  color: #3e2093;
}
.right-side .input-box{
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea{
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #F0F1F8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box{
  min-height: 110px;
}
.right-side .input-box textarea{
  padding-top: 6px;
}
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"]{
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="button"]:hover{
  background: #5029bc;
}

@media (max-width: 950px) {
  .container{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  .container .content .right-side{
   width: 75%;
   margin-left: 55px;
}
}
@media (max-width: 820px) {
  .container{
    margin: 40px 0;
    height: 100%;
  }
  .container .content{
    flex-direction: column-reverse;
  }
 .container .content .left-side{
   width: 100%;
   flex-direction: row;
   margin-top: 40px;
   justify-content: center;
   flex-wrap: wrap;
 }
 .container .content .left-side::before{
   display: none;
 }
 .container .content .right-side{
   width: 100%;
   margin-left: 0;
 }
}
.Submit-btn{
  background: #3e2093;
border: none;
border-radius: 6px;
color: #fff;
cursor: pointer;
font-size: 18px;
outline: none;
padding: 8px 16px;
transition: all .3s ease;
}

@media screen and (min-device-width: 320px) and (max-device-width: 380px){
  .Locations-container{
    justify-content: center;
  }
}