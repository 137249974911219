.clients-container{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(230, 230, 230);
}
.Clients-heading{
    width: 30%;
}
.col-22{
    width: 100%;
    padding: 20px 80px;
}
.col-22-inner{
    font-size: 20px;
    width: 100%;
    text-align: center;
}

.client-logos-container{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Client-logo{
    width: 150px;
    height: 150px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
/* -------Services-box------------ */
.Services-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 20px;
    gap:20px;
}
.Services-box{
    text-decoration: none;
    cursor: pointer;
    color: black;
    width: 550px;
    height: 350px;
    display: flex;
    align-items: flex-end;
    justify-content:center ;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
}
.Services-box:hover{
box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}


.Home-cities{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

    gap: 20px;
}

/* ----------background-box-services-------- */
.Security-solutions{
    background-image: url(../../Copmponents/Assets/securityBox.jpg);
}
.Facility-solutions{
    background-image: url(../../Copmponents/Assets/FacilityBox.jpg);
}
.Bg-solutions{
    background-image: url(../../Copmponents/Assets/BgBox.jpg);
}
.Health-solutions{
    background-image: url(../../Copmponents/Assets/HealthBox.jpg);
}
.Services-box>h2{
    padding: 10px 0px;
    width: 95%;
    font-size: 30px;
    text-align: center;
    background-color: #ffff;
    margin-bottom: 60px;
}


@media screen and (min-device-width: 320px) and (max-device-width: 380px){
    .col-22-inner>h1{
        font-size: 20px;
    }
    .col-22-inner>p{
        font-size: 16px;
    }
    .col-22-inner{
        text-align: justify;
    }
    .col-22{
        width: 100%;
        padding: 10px 15px;
    }
    .Services-box > h2{
        font-size: 20px;
    }
    .Home-cities div> h1{
        font-size: 20px;
    }
    .Locations-container{
        justify-content: center;
    }
    .clients-container{
        flex-direction: column;
    }
    .clients-container>h1{
        font-size: 20px;
    }
}