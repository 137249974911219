.footer-container{
    width: 100%;
    padding: 20px;
    display: flex;
    background-color: #121212;
    color: white;
    margin-bottom: 0px;
}
.Locations-info{
    width: 50%;
}
.Address-footer{
    width: 50%;
    
}
.Copyrights{
    text-align: center;
}