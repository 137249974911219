

.slider77 {
    position: relative;
    width: 100%;
    /* height: 700px; */
    display: flex;
   box-sizing: border-box;
    overflow: hidden;
    
  }
  
  .slide77 {
    position: relative;
    min-width: 100%;
    transition: 500ms ease-in;
    /* margin-top: -100px; */
    overflow: hidden;
  }
  
  .slider-buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8%;
    height: 80vh;
    background: none;
    border: none;
    outline: none;
    transition: all 500ms ease-in;
  }
  
  .slider-buttons>i {
    font-size: 2rem;
    color: white;
  }
  
  #goLeft {
    left: 0;
  }
  
  #goRight {
    right: 0;
  }
  
  @media screen and (min-device-width:320px)and (max-device-width:380px){
  
    .slider-buttons>i{
      font-size: 16px;
    }
  
  }
  
  @media screen and (min-device-width:380px)and (max-device-width:480px){
  
    .slider-buttons>i{
      font-size: 18px;
    }
  
  }
  
  @media screen and (min-device-width:480px)and (max-device-width:585px){
  
    .slider-buttons>i{
      font-size: 18px;
    }
  
  }
  
  @media screen and (min-device-width:585px)and (max-device-width:768px){
    .slider-buttons>i{
      font-size: 18px;
    }
  
  }
  
  
  @media screen and (min-device-width:768px)and (max-device-width:920px){
  
    .slider-buttons>i{
      font-size: 30px;
    }
  
  }
  
  @media screen and (min-device-width:920px)and (max-device-width:1024px){
  
    .slider-buttons>i{
      font-size: 40px;
    }
  }
  
  @media screen and (min-device-width:1024px)and (max-device-width:1280px){
  
    .slider-buttons>i{
      font-size: 40px;
    }
  
  }
  
  @media screen and (min-device-width:1390px)and (max-device-width:1490px){
   .slider-buttons>i{
      font-size: 40px;
    }
  
  }
  
  
  
  
  