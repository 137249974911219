
.bg-verify-hero{
    width: 100%;
}
.bg-verify-hero>img{
    width: 100%;
}

.bgv-col2{
    background-color:#2e3192 ;
 padding: 20px 100px;
 text-align: center;
 color: #fff;
}
.bgv-col2>p{
    padding: 10px 0px;
    font-size: 20px;
    text-align: justify;
}

.bg-services-col3{
    width: 100%;
    display: flex;
    padding: 20px;
    justify-content: center;
}
.Bg-b2b{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.bg-service-list>li{
    font-size: 20px;
}
.bg-verify-service{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bgv-content-text{
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bgv-content-text>p{
    font-size: 18px;
}

.ServiceItem>p{
    background-color: #fff;
    font-size: 20px;
    width: 100%;
}
.ServiceItem:hover{
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.ServiceItem{
    border: 2px solid rgb(207, 207, 207);
    width: 250px;
    height: 250px;
    text-align: center;
    background-color: black;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.ServicesList-Bg{
    padding: 20px;
    margin: 20px 0px;
    color: #000000;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:30px
}
.SL-1{

    background-image:url(../../Copmponents/Assets/BgVerSerLIst/HouseVerify.jpg) ;
}
.SL-2{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/AcadamicVerification.jpg);
}
.SL-3{
   background-image: url(../../Copmponents/Assets/BgVerSerLIst/PreviousVerification.jpg);
}
.SL-4{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/ReferenceVerification.jpg);
}
.SL-5{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/OFAC\ Check.jpg);
}
.SL-6{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/exitInterview.jpg);
}
.SL-7{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/GapVerification.jpg);
}
.SL-8{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/CriminalRecordVerification.jpg);
}
.SL-9{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/DrugTest.jpg);
}
.SL-10{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/VendorDueDiligence.jpg);
}
.SL-11{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/Leadership\ Verification.jpg);
}
.SL-12{
    background-image: url(../../Copmponents/Assets/BgVerSerLIst/Tenantverification.jpg);
}