.Abt-hero{
    width: 100%;
}
.Abt-hero>img{
    width: 100%;
}
.abt-col-1{
 padding: 20px 50px;
 text-align: center;
}
.abt-col-1>p{
    font-size: 20px;
}
.abt-ctn-main{
    width: 100%;
    padding: 20px 150px;
}
.abt-col-2{
    width:100%;
  
    display: flex;
    margin-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.left-img-abt{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left-img-abt>img{
    width: 100%;
}
.right-content-abt{
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: justify;
    align-items: center;
    justify-content: center;
    padding: 15px;
    
}
.right-content-abt>h2{
    font-size: 30px;
}

.right-content-abt>p{
    font-size: 20px;
}