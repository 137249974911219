.security-content{
    padding: 30px 100px;
    font-size: 20px;
    text-align: center;
}
.hero-security-22{
    width: 100%;
}
.hero-security-22>img{
    width: 100%;
}
.features-box-container{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap:15px;
}
.heading33{
    text-align: center;
}
.feature-box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    gap:10px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    width: 400px;
    height: 300px;
    background-color: #2e3192;
    color: #ffff;
    text-align: left;
}
.features-securatus{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.heading-sf-13{
    width: 100%;
    text-align: center;
}
.security99{
    width: 400px;
    height: 250px;
    background-color: aliceblue;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.security99>h2{
    width: 100%;
    margin-bottom: 40px;
    padding: 10px 0px;
    background-color:#2e3192;
    color: #ffff;
    font-size: 20px;
    text-align: center;
  
}
.sf-1{
    background-image: url(../../Copmponents/Assets/cctv.jpg);
}
.sf-2{
    background-image: url(../../Copmponents/Assets/Alarm.jpg);
}
.sf-3{
    background-image: url(../../Copmponents/Assets/Remote.jpg);
}
.sf-4{
    background-image: url(../../Copmponents/Assets/Automation.jpg);
}
.sf-5{
    background-image: url(../../Copmponents/Assets/systemManagement.jpg);
}
@media screen and (min-device-width: 320px) and (max-device-width: 380px){
    .security-content{
        padding: 10px;
        text-align: justify;
    }
    .security-content>h2{
font-size: 18px;
    }
     .security-content>p{
        font-size: 16px;
     }
}